import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/page-header"
import Container from "../../components/container"
import Product from "../../components/product"
import styles from "./Urunler.module.scss"

export default function Urunler({ data }) {
  return (
    <Layout>
      <SEO title="Ürünler" />
      <PageHeader title="Ürünler" />
      <Container>
        <div className={styles.urunler}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Product
              key={node.frontmatter.slug}
              to={`/urunler/${node.frontmatter.slug}`}
              name={node.frontmatter.title}
              image={node.frontmatter.image.sharp.fluid}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/products/**/*.md" } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            image {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    pageHeaderImage: file(
      relativePath: { eq: "ozel-civata-ozel-parcalar-3.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
