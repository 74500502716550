import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Title from "../title"
import styles from "./product.module.scss"

export default function Product({ name, image, ...props }) {
  return (
    <Link className={styles.product} {...props}>
      <div className={styles.image}>
        <Img fluid={image} alt={name} />
      </div>
      <Title headingLevel="h4" className={styles.title}>
        {name}
      </Title>
    </Link>
  )
}
